export const TeamData = [
    {
        list: 'operating',
        name: 'Paula Figueroa',
        description: 'Go to Market',
        resume: [
            'Former PayTV & Video Vice President for Telefonica Latam',
            'Former CMO Movistar Chile. And long experience in telecommunications sector in more than 10 countries',
            'Member of Board in several sectors: Insurance, Education, Entrepreneurship and Innovation, Media and Telco'
        ],
        resumeEs: [
            'Ex Vicepresidente de PayTV & Video para Telefónica Latam',
            'Ex CMO Movistar Chile. Y larga experiencia en el sector de las telecomunicaciones en más de 10 países',
            'Miembro de la Junta en varios sectores: Seguros, Educación, Emprendimiento e Innovación, Medios de comunicación y Telco'
        ],
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Paula+Figueroa+1.webp',
        linkedin: 'https://www.linkedin.com/in/paula-figueroa-4666825b'
    },
    {
        list: 'operating',
        name: 'Renato Motta',
        description: 'Business Development',
        resume: ['Former COO of DIRECTV Panamericana', 'Former Group Vice President Billing Design, Operations and Assurance at Charter Communications', 'Over 20 years of TMT experience in the US and Latin America'],
        resumeEs: ['Ex COO de DIRECTV Panamericana', 'Ex Vicepresidente de Grupo de Diseño de Facturación, Operaciones y Garantía en Charter Communications', 'Más de 20 años de experiencia en TMT en EE. UU. y América Latina'],
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/RenatoMotta',
        linkedin: 'https://www.linkedin.com/in/mottarenato'
    },
    {
        list: 'operating',
        name: 'Gabriel Miró Quesada',
        description: 'Business Strategy',
        resume: ['Former Board President at Editorial El Comercio Peru', 'Board member in several media and newspapers companies at Latam', 'Committee member at Fibra Prime', 'Board member at El Comercio Group in Peru', 'Board President at MiFibra Peru'],
        resumeEs: ['Ex Presidente de la Junta en Editorial El Comercio Perú', 'Miembro de la junta en varias empresas de medios y periódicos en Latam', 'Miembro del comité en Fibra Prime', 'Miembro de la junta en el Grupo El Comercio en Perú',
            'Presidente del directorio en MiFibra Perú'
        ],
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/GabrielMiro%CC%81',
        linkedin: 'https://www.linkedin.com/in/gabrielmiroquesada/'
    },
    {
        list: 'operating',
        name: 'Jose Luis Marrero',
        description: 'Media Strategy',
        resume: ['Former AVP, Advertising Sales at DIRECTV Latin America.', 'Over 20 years of experience in Marketing, Advertising Sales, and Content for linear and digital platforms at DIRECTV Latin America and AT&T.', 'Responsible for leading Simpletv’s MEDIA business unit and transformation of the content strategy, monetization, and distribution on multiple platforms'],
        resumeEs: ['Ex AVP, Ventas Publicitarias en DIRECTV Latin America.', 'Más de 20 años de experiencia en Marketing, Ventas Publicitarias y Contenidos para plataformas lineales y digitales en DIRECTV Latin America y AT&T.', 'Responsable de liderar la unidad de negocio de MEDIA de Simpletv y la transformación de la estrategia de contenidos, monetización y distribución en múltiples plataformas'],
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Jose%CC%81',
        linkedin: 'https://www.linkedin.com/in/josemarrerou/'
    },
    {
        col: 2,
        list: 'operating',
        name: 'Rick Nerod',
        description: 'Content Strategy',
        resume: ['Former Chief Content Officer for Directv Latin America (Vrio)', 'Over 20 years of experience managing content offers and developing programming for Pay-TV and streaming platforms', 'Currently advising Scale Capital on content offer for Simple TV and other content opportunities.'],
        resumeEs: ['Ex Director de Contenidos de Directv Latin America (Vrio)', 'Más de 20 años de experiencia gestionando ofertas de contenidos y desarrollando programación para plataformas de televisión de pago y streaming', 'Actualmente asesorando a Scale Capital en la oferta de contenidos para Simple TV y otras oportunidades de contenidos.'],
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Rick',
        linkedin: 'https://www.linkedin.com/in/richard-nerod-14a5b4a2/'
    },
    {
        col: 2,
        list: 'operating',
        name: 'Fernando Salas',
        description: 'Customer Operations',
        resume: ['Former VP of Service and Billing Assurance at Charter Communications', 'Former Senior Director of Operations at DIRECTV Panamericana', 'Over 20 years of Telecommunications and Media experience in the US and LATAM'],
        resumeEs: [' Ex VP de Auditoría de Servicio y Facturación en Charter Communications.', ' Ex Director Senior de Operaciones en DIRECTV Panamericana.', 'Más de 20 años de experiencia en Telecomunicaciones en USA y Latinoamérica.'],
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/b040cb62-6ee1-4ff8-bede-53ee4367e956.png',
        linkedin: 'https://www.linkedin.com/in/fernandosalasd/'
    },
    {
        list: 'gp',
        name: 'Javier Valenzuela',
        description: 'VP Value Creation',
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Javier.webp',
        linkedin: 'https://www.linkedin.com/in/javier-valenzuela-ib%C3%A1%C3%B1ez-8a14092/'
    },
    {
        list: 'gp',
        name: 'Samuel Basulto',
        description: 'VP TS & Controller',
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Samuel.webp',
        linkedin: 'https://www.linkedin.com/in/samuel-basulto/'
    },
    // {
    //     col: 2,
    //     list: 'gp',
    //     name: 'Branco Kegevic',
    //     description: 'Financial Business Sr. Analyst',
    //     logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Branco.webp',
    //     linkedin: 'https://www.linkedin.com/in/branco-kegevic-hazleby-19183317b/'
    // },
    {
        col: 2,
        list: 'gp',
        name: 'Maria De Cesaris',
        description: 'Accounting Sr. Analyst',
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Maria.webp',
        linkedin: 'https://www.linkedin.com/in/maria-angiolina-de-cesaris-tua-3a9643176/'
    },
    {
        col: 2,
        list: 'gp',
        name: 'Aysegul Secim',
        description: 'Financial Ops Sr. Analyst',
        logo: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Aysegul.webp',
        linkedin: 'https://www.linkedin.com/in/aysegul-secim-9367b754/'
    }

]